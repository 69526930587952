<!--Pantalla Listado-->

 
<!--
<app-listado>
</app-listado> -->

<!--Fin pantalla listado-->

<!--Pantalla llamado-->

<!--<app-llamado>
</app-llamado>-->

<!--Fin pantalla llamado-->
<router-outlet></router-outlet>
