import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-defaulttemplate',
    templateUrl: './defaultTemplate.component.html',
  })
  export class DefaultTemplateComponent {
    @Input() pac: any;
    @Input() bShowLlamado: any;
    @Input() medico: any;
    @Input() piso: any;
    @Input() box: any;
    @Input() paciente: any;
    @Input() bEmpty: any;
    @Input() empresa: any;
   
    constructor(){
      }
    }