import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-template1-cop',
    templateUrl: './template1-cop.component.html',
  })
  export class Template1CopComponent {
    @Input() pac: any;
    @Input() bShowLlamado: any;
    @Input() medico: any;
    @Input() piso: any;
    @Input() box: any;
    @Input() paciente: any;
    @Input() bEmpty: any;
    @Input() empresa: any;
   
    constructor(){
      }
    }