import { TitleService } from './../services/title.service';
import { SignalRService } from './../services/signalr.service';
import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '../app.config';
const {Howl, Howler} = require('../../assets/howler/howler.js');
@Component({
  selector: 'app-llamado',
  templateUrl: './llamado.component.html',
  styleUrls: ['./llamado.component.css']
})
export class LlamadoComponent implements OnInit {
  @Input() public medico: string = '';
  @Input() public piso: string = '';
  @Input() public paciente: string = '';
  @Input() public box: string = '';
  @Input() public soloSonido: boolean = false;
  logo:any;
  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private signalRService: SignalRService,
    private titleService: TitleService,
    private config: AppConfig
  ) { }

  async ngOnInit() {
    const empresa = await this.config.getEmpresa(sessionStorage.getItem('empresaSlug'))
    this.logo = empresa.logo_white;
    var sound = new Howl({
      src: '../../assets/mp3/Llamado.mp3',
      volume: 0.5,
    });
    sound.play();
     setTimeout(()=>{
        this.titleService.disableButton(false);
       },3000);

  }
}
