import { Component } from '@angular/core';
import disableDevTool from 'disable-devtool';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'llamado';
  constructor(){
    const options: any = {
      tkName: 'key',
      md5:disableDevTool.md5('ttpPanMed')
    }
    disableDevTool(options);
  }
}
