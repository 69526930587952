<div class="fluid llamado-bg" *ngIf="!soloSonido">
    <div class="row  text-center">
        <div class="col-12">
            <img [src]="logo" style="margin-top:10px;height: 100px;max-width: 236;" />
        </div>
        <div class="col-12 texto-1 text-morado">
            Llamando a
        </div>
        <div class="col-12 texto-2 text-morado">
            {{paciente}}
        </div>
        <div class="col-12 texto-3 text-morado">
            <div class="">DR. &nbsp; {{medico}}</div>
        </div>
        <div class="col-12 texto-4 text-morado">
            Piso {{piso}}
        </div>
        <div class="col-12 texto-4 text-morado">
            Box {{box}}
        </div>
    </div>
</div>