import { Injectable } from '@angular/core';
import { AppConfig  } from './../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private config: AppConfig, private httpClient: HttpClient) {
  }
  async getToken(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    const apiUrl    = await this.config.get("apiUrl");
    const userToken = await this.config.get("userToken");
    const passToken = await this.config.get("passToken");
    let tokenBody = {
      'email'     : userToken,
      'password'  : passToken
    };
    try {
      return await this.httpClient.post(apiUrl + 'v2/identity/login', tokenBody, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'tokenid'  : '-1'
      };
      return resultado;
    }
  }


  async listarPacientes(idPantalla){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    let rut = sessionStorage.getItem('rut');
    let idEmpresa = await this.config.get("idEmpresa");
    try {
      return await this.httpClient.get<any[]>(apiUrl + `web/v3/pantallamedico?idPantalla=${idPantalla}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
   //   return resultado;
    }
  }
}
