import { SignalRService } from './services/signalr.service';
import { AppConfig } from './app.config';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LlamadoComponent } from './llamado/llamado.component';
import { ListadoComponent } from './listado/listado.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IndexComponent } from './index/index.component';
import { DefaultTemplateComponent } from './templates/defaultTemplate/defaultTemplate.component';
import { Template1CopComponent } from './templates/template1-cop/template1-cop.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LlamadoComponent,
    ListadoComponent,
    IndexComponent,
    DefaultTemplateComponent,
    Template1CopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule
  ],
  exports:[
  ],

  providers: [
    SignalRService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
